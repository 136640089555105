import React, { useEffect } from 'react'
import { useLocation } from "react-router-dom";
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import styles from '../css/home.module.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Avatar1 from '../../Image/2/1.jpg'
import Avatar2 from '../../Image/2/2.jpg'
import Avatar3 from '../../Image/2/3.jpg'
import Avatar4 from '../../Image/2/4.jpg'
import Avatar5 from '../../Image/2/5.jpg'
import Avatar6 from '../../Image/2/6.jpg'
import Avatar7 from '../../Image/2/7.jpg'
const RenovationOfClass = () => {


    const { state } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [state]);


    const options = {
        loop: false, // Disable infinite looping
        rewind: true, // Restart carousel when reaching the end
        margin: 10,
        nav: true,
        autoplay: true,
        items: 3,
        responsive: {
            0: { items: 1 }, // 1 item for screens up to 600px
            600: { items: 2 }, // 2 items for screens between 600px and 1000px
            1000: { items: 3 }, // 3 items for screens above 1000px
        },
    };
    return (
        <>
            <Nav />

            <section className={styles.boxAbtOne}>
                <div className="row">
                    <div className="col-lg-11">
                        <h4 className="abtInfoTop wow fadeInUp" data-wow-duration="1s">Renovation of Six Classrooms in Benin City, Edo State
                        </h4>
                        <p className="paragraph wow fadeInUp" data-wow-duration="1.2s">Renovating six classrooms in Benin City, Edo State, is a commendable initiative that can greatly
                            enhance the learning environment for students.</p>
                    </div>
                </div>
            </section>

            <section className={styles.boxAbtOne}>
                <div className="row mb-5">
                    <div className="col-lg-12 mb-4">
                        <h2 className="keyStepText  wow fadeInUp" data-wow-duration="1.2s"> key steps to consider for
                            such a project:</h2>
                    </div>

                    <div className="col-lg-6 mb-2">
                        <div>
                            <div className="circleDivCarrier wow fadeInUp" data-wow-duration="1s">
                                <div className='drawCircle mt-2'></div>
                                <h2 className="mg-bottom-24px wow fadeInUp fontWeightText" data-wow-duration="1s"> Project Planning and Assessment</h2>
                            </div>
                            <p className="paragraph wow fadeInUp" data-wow-duration="1.2s"><span className='subHeading'>Site Inspection:</span> Conduct a detailed assessment of the classrooms to identify specific needs such as structural repairs, painting, roofing, plumbing, or electrical upgrades.</p>


                            <p className="paragraph wow fadeInUp" data-wow-duration="1.2s"><span className='subHeading'>Budget Estimation:</span> Develop a comprehensive budget, including costs for materials, labor, and
                                contingency funds</p>

                            <p className="paragraph wow fadeInUp" data-wow-duration="1.2s"><span className='subHeading'>Stakeholder Engagement::</span>Involve school authorities, local government, parents, and community
                                members to gather support and input.</p>
                        </div>
                    </div>

                    <div className="col-lg-6 mb-2">
                        <div>
                            <div className="circleDivCarrier wow fadeInUp" data-wow-duration="1s">
                                <div className='drawCircle mt-2'></div>
                                <h2 className="mg-bottom-24px wow fadeInUp fontWeightText" data-wow-duration="1s">  Design and Approvals</h2>
                            </div>
                            <p className="paragraph wow fadeInUp" data-wow-duration="1.2s"><span className='subHeading'> Layout Plan:</span>  Draft a renovation plan focusing on structural safety, improved ventilation, lighting,
                                and modern classroom aesthetics</p>


                            <p className="paragraph wow fadeInUp" data-wow-duration="1.2s"><span className='subHeading'> Permits:</span>  Obtain any necessary approvals from local authorities or education boards.s</p>
                        </div>
                    </div>

                </div>

                <div className="row mb-5">

                    <div className="col-lg-6 mb-2">
                        <div>
                            <div className="circleDivCarrier wow fadeInUp" data-wow-duration="1s">
                                <div className='drawCircle mt-2'></div>
                                <h2 className="mg-bottom-24px wow fadeInUp fontWeightText" data-wow-duration="1s"> Procurement of Materials</h2>
                            </div>
                            <p className="paragraph wow fadeInUp" data-wow-duration="1.2s"> Source durable and cost-effective materials locally to reduce transportation costs and support the community economy.</p>
                        </div>
                    </div>

                    <div className="col-lg-6 mb-2">
                        <div>
                            <div className="circleDivCarrier wow fadeInUp" data-wow-duration="1s">
                                <div className='drawCircle mt-2'></div>
                                <h2 className="mg-bottom-24px wow fadeInUp fontWeightText" data-wow-duration="1s"> Renovation Process</h2>
                            </div>
                            <p className="paragraph wow fadeInUp" data-wow-duration="1.2s"><span className='subHeading'> Structural Repairs:</span>  Address foundation issues, wall cracks, and roof leaks.</p>


                            <p className="paragraph wow fadeInUp" data-wow-duration="1.2s"><span className='subHeading'>  Electrical and Plumbing Work:</span>   Install or repair electrical fittings, wiring, fans, and water facilities.</p>

                            <p className="paragraph wow fadeInUp" data-wow-duration="1.2s"><span className='subHeading'>   Painting and Furnishings:</span>  Use eco-friendly paints and ensure proper seating arrangements.</p>

                            <p className="paragraph wow fadeInUp" data-wow-duration="1.2s"><span className='subHeading'>   Technology Integration:</span>    Consider adding smartboards, projectors, or charging ports for digital
                                learning.</p>
                        </div>
                    </div>

                </div>

                <div className="row mb-5">

                    <div className="col-lg-6 mb-2">
                        <div>
                            <div className="circleDivCarrier wow fadeInUp" data-wow-duration="1s">
                                <div className='drawCircle mt-2'></div>
                                <h2 className="mg-bottom-24px wow fadeInUp fontWeightText" data-wow-duration="1s">  Project Management</h2>
                            </div>

                            <p className="paragraph wow fadeInUp" data-wow-duration="1.2s"><span className='subHeading'>  Hiring Skilled Labor:</span>  Employ qualified contractors and artisans, preferably from the local
                                community.</p>

                            <p className="paragraph wow fadeInUp" data-wow-duration="1.2s"><span className='subHeading'> Timeline Monitoring:</span> Set achievable milestones to ensure timely completion</p>

                            <p className="paragraph wow fadeInUp" data-wow-duration="1.2s"><span className='subHeading'>  Quality Control: </span>  Regularly inspect the work to ensure standards are met.</p>

                        </div>
                    </div>

                    <div className="col-lg-6 mb-2">
                        <div>
                            <div className="circleDivCarrier wow fadeInUp" data-wow-duration="1s">
                                <div className='drawCircle mt-2'></div>
                                <h2 className="mg-bottom-24px wow fadeInUp fontWeightText" data-wow-duration="1s">Handover and Sustainability</h2>
                            </div>
                            <p className="paragraph wow fadeInUp" data-wow-duration="1.2s"><span className='subHeading'> Handover Ceremony:</span> Organize an event to formally hand over the renovated classrooms to the
                                school..</p>


                            <p className="paragraph wow fadeInUp" data-wow-duration="1.2s"><span className='subHeading'>      Maintenance Plan: </span> Establish a routine maintenance schedule and allocate funds or resources for
                                future upkeep.</p>
                        </div>
                    </div>

                </div>

                <div className="row mb-5">

                    <div className="col-lg-6 mb-2">
                        <div>
                            <div className="circleDivCarrier wow fadeInUp" data-wow-duration="1s">
                                <div className='drawCircle mt-2'></div>
                                <h2 className="mg-bottom-24px wow fadeInUp fontWeightText" data-wow-duration="1s">  Impact Measurement
                                </h2>
                            </div>

                            <p className="paragraph wow fadeInUp" data-wow-duration="1.2s"><span className='subHeading'>Feedback Collection:</span>  Gather feedback from students and teachers to evaluate the project's
                                success</p>

                            <p className="paragraph wow fadeInUp" data-wow-duration="1.2s"><span className='subHeading'>Reporting:</span>  Document the process with before-and-after photos, costs, and lessons learned for
                                future projects.</p>


                        </div>
                    </div>

                </div>


                {/* <div className="row mb-4">

                <div className="col-lg-4 mb-5">
                    <div className='solarImg'>
                        <img src={Avatar} s alt='img' />
                    </div>
                </div>

                <div className="col-lg-4 mb-5">
                    <div className='solarImg'>
                        <img src={Avatar} s alt='img' />
                    </div>
                </div>

                <div className="col-lg-4 mb-5">
                    <div className='solarImg'>
                        <img src={Avatar} s alt='img' />
                    </div>
                </div>

            </div> */}

                <OwlCarousel className="owl-theme" {...options}>
                    <div className="item">
                        <div className='solarImg'>
                            <img src={Avatar1} s alt='img' />
                        </div>
                    </div>
                    <div className="item">
                        <div className='solarImg'>
                            <img src={Avatar2} s alt='img' />
                        </div>
                    </div>
                    <div className="item">
                        <div className='solarImg'>
                            <img src={Avatar3} s alt='img' />
                        </div>
                    </div>
                    <div className="item">
                        <div className='solarImg'>
                            <img src={Avatar4} s alt='img' />
                        </div>
                    </div>
                    <div className="item">
                        <div className='solarImg'>
                            <img src={Avatar5} s alt='img' />
                        </div>
                    </div>

                    <div className="item">
                        <div className='solarImg'>
                            <img src={Avatar6} s alt='img' />
                        </div>
                    </div>
                    <div className="item">
                        <div className='solarImg'>
                            <img src={Avatar7} s alt='img' />
                        </div>
                    </div>
                </OwlCarousel>


                {/* <div className="row mb-5">
            <div className="col-lg-10">
                    <div className="ser">
                            <h2 className="mg-bottom wow fadeInUp keyStepText"  data-wow-duration="1s">Benefits for Idama Community:</h2>
                    </div>

                    <div className="circleDivCarrier wow fadeInUp"  data-wow-duration="1s">
                            <div className='drawCircle1 mt-1'></div>
                            <p className="paragraph mg-bottom-24px wow fadeInUp "  data-wow-duration="1s">Enhanced Safety: Improved lighting in public areas reduces accidents and enhances security.</p>
                    </div>

                    <div className="circleDivCarrier wow fadeInUp"  data-wow-duration="1s">
                            <div className='drawCircle1 mt-1'></div>
                            <p className="paragraph mg-bottom-24px wow fadeInUp "  data-wow-duration="1s">Economic Savings: Eliminates the recurring costs associated with fuel-powered lighting.</p>
                    </div>
                    
                    <div className="circleDivCarrier wow fadeInUp"  data-wow-duration="1s">
                            <div className='drawCircle1 drawCircle4 mt-1'></div>
                            <p className="paragraph mg-bottom-24px wow fadeInUp "  data-wow-duration="1s">Environmental Impact: Reduces carbon emissions, contributing to a cleaner and greener environment.</p>
                    </div>

                    
                    <div className="circleDivCarrier wow fadeInUp"  data-wow-duration="1s">
                            <div className='drawCircle1 drawCircle3 mt-1'></div>
                            <p className="paragraph mg-bottom-24px wow fadeInUp "  data-wow-duration="1s">Empowered Community: Access to lighting extends business hours, supports education, and fosters community activities.</p>
                    </div>

                </div>
            </div>

            
            <div className="col-lg-10">
                <div className="mt-5 mb-5">
                    <p className="mg-bottom wow fadeInUp fontWeightTex"  data-wow-duration="1s">Installing solar lights in Idama Community of Rivers State is a step toward resilience and sustainable development, illuminating not just pathways but the potential for growth and progress.</p>
                </div>
            </div> */}

            </section>

            <Footer />

        </>
    )
}

export default RenovationOfClass