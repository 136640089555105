import React, { useEffect } from 'react'
import { useLocation } from "react-router-dom";
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import styles from  '../css/home.module.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Avatar1 from '../../Image/4/1.jpg'
import Avatar2 from '../../Image/4/2.jpg'
import Avatar3 from '../../Image/4/3.jpg'
import Avatar4 from  '../../Image/4/4.jpg'
import Avatar5 from '../../Image/4/5.jpg'
import Avatar6 from '../../Image/4/6.jpg'
import Avatar7 from '../../Image/4/7.jpg'

const FourClassroomsPage = () => {

    const { state } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [state]);

    const options = {
        loop: false, // Disable infinite looping
        rewind: true, // Restart carousel when reaching the end
        margin: 10,
        nav: true,
        autoplay: true,
        items: 3,
        responsive: {
            0: { items: 1 }, // 1 item for screens up to 600px
            600: { items: 2 }, // 2 items for screens between 600px and 1000px
            1000: { items: 3 }, // 3 items for screens above 1000px
          },
      };
  return (
    <>
        <Nav />

        <section className={styles.boxAbtOne}>
            <div className="row">
                <div className="col-lg-12">
                    <h4 className="abtInfoTop wow fadeInUp"  data-wow-duration="1s"> Construction of Four classrooms unit in Benin city, Edo State</h4>
                    <p className="paragraph wow fadeInUp"  data-wow-duration="1.2s">The construction of a four-classroom unit in Benin City, Edo State, involves the development of a modern, well-equipped educational facility designed to accommodate a growing number of students. Revaloneq Nig. Ltd began the project with site preparation, including clearing the land and laying a solid foundation. The building structure is then erected using durable materials such as reinforced concrete and steel, ensuring safety and longevity.</p>
                </div>

                                
                <div className="col-lg-11 mt-3">
                    <p className="paragraph wow fadeInUp"  data-wow-duration="1.2s">Each classroom is designed for optimal space, ventilation, and lighting, with large windows and adequate air circulation to create a conducive learning environment.The classrooms are equipped with essential amenities such as blackboards, seating arrangements, and electrical fittings, including lighting and fans for comfort. The construction also includes the development of basic infrastructure, such as toilets, water supply, and drainage systems, to ensure the school's functionality and sanitation.</p>
                </div>

                                
                <div className="col-lg-11 mt-3">
                    <p className="paragraph wow fadeInUp"  data-wow-duration="1.2s">The project aims to enhance the quality of education in the area by providing a safe, comfortable, and well-equipped learning environment for students. It also contributes to the state's educational development by addressing overcrowding in existing schools and providing access to better facilities. Upon completion, the four-classroom unit will serve as a valuable resource for the local community, improving educational outcomes for students in Benin City.</p>
                </div>

            </div>

            <OwlCarousel className="owl-theme" {...options}>
                <div className="item">
                    <div className='solarImg'>
                        <img src={Avatar1} s alt='img' />
                    </div>
                </div>
                <div className="item">
                    <div className='solarImg'>
                        <img src={Avatar2} s alt='img' />
                    </div>
                </div>
                <div className="item">
                    <div className='solarImg'>
                        <img src={Avatar3} s alt='img' />
                    </div>
                </div>
                <div className="item">
                    <div className='solarImg'>
                        <img src={Avatar4} s alt='img' />
                    </div>
                </div>
                <div className="item">
                    <div className='solarImg'>
                        <img src={Avatar5} s alt='img' />
                    </div>
                </div>
                
                <div className="item">
                    <div className='solarImg'>
                        <img src={Avatar6} s alt='img' />
                    </div>
                </div>
                <div className="item">
                    <div className='solarImg'>
                        <img src={Avatar7} s alt='img' />
                    </div>
                </div>
            </OwlCarousel>

        </section>



        <Footer />
    </>
  )
}

export default FourClassroomsPage