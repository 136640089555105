import React from 'react'
import Nav from './components/Nav'
import Footer from './components/Footer'
import styles from  './css/home.module.css';
import { Link } from "react-router-dom";

const Projects = () => {
  return (
    <>
        <Nav />

        <section className={styles.boxAbtOne}>
            <div className="row">
                <div className="col-lg-6">
                    <h4 className="abtInfoTop wow fadeInUp"  data-wow-duration="1s">Our Projects</h4>
                    {/* <p className="paragraph wow fadeInUp"  data-wow-duration="1.2s">We are a fast growing real estate company rapidly spreading its frontiers across Nigeria, with sole interest in sales/purchase of properties construction, human capital developmen and consultancy.</p> */}
                </div>
            </div>
          </section>

          <section className={styles.boxAbtOne}>
              <div className="row mb-5">
                <div className="col-lg-6 mb-5">
                    <div>
                        <Link to="/Solar_installation_Page">
                            <div className=" serv2Carrier1 wow fadeInUp"  data-wow-duration="1s">
                                <div className='servDiv'>
                                    <h2 className="mg-top-24px wow fadeInUp "  data-wow-duration="1s">Installation of solar light</h2>
                                    <p>Location: Idama, River State</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="col-lg-6 mb-5">
                    <div> 
                        <Link to="/Renovation_Of_Classroom_Detail">
                            <div className=" serv2Carrier2 wow fadeInUp"  data-wow-duration="1s">
                                <div className='servDiv'>
                                <h2 className=" wow fadeInUp "  data-wow-duration="1s">Renovation of six classroom</h2>
                                <p>Location: Benin City, Edo State</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
              </div>

              
              <div className="row mb-5">
                <div className="col-lg-6 mb-5">
                    <div>
                        <Link to="/Solar_light_detail">
                            <div className=" serv2Carrier3 wow fadeInUp"  data-wow-duration="1s">
                                <div className='servDiv'>
                                    <h2 className="mg-top-24px wow fadeInUp "  data-wow-duration="1s">Installation of solar light</h2>
                                    <p>Location: GRA phase 1 Port harcourt, Rivers state</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
                <div className="col-lg-6 mb-5">
                    <div>  
                        <Link to="/Four_Classroom_Detail">
                            <div className=" serv2Carrier4 wow fadeInUp"  data-wow-duration="1s">
                                <div className='servDiv'>
                                <h2 className=" wow fadeInUp "  data-wow-duration="1s">Construction of Four classrooms</h2>
                                <p>Location: Benin City, Edo State</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
              </div>
                            
              <div className="row mb-5">
                <div className="col-lg-6 mb-5">
                    <div>
                        <Link to="/Supply_Detail_Page">
                            <div className=" serv2Carrier wow fadeInUp"  data-wow-duration="1s">
                                <div className='servDiv'>
                                    <h2 className="mg-top-24px wow fadeInUp "  data-wow-duration="1s"> Supply of 220Kva and 250Kva Solar light</h2>
                                    <p>Location: Entero homes, Rivers state</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                </div>
              </div>

          </section>


        <Footer />
    </>
  )
}

export default Projects