import React, { useEffect } from 'react'
import { useLocation } from "react-router-dom";
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import styles from  '../css/home.module.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Avatar1 from '../../Image/5/1.jpg'
import Avatar2 from '../../Image/5/2.jpg'
import Avatar3 from '../../Image/5/3.jpg'
import Avatar4 from  '../../Image/5/4.jpg'
import Avatar5 from '../../Image/5/5.jpg'

const SupplyDetailPage = () => {
    const { state } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [state]);

    const options = {
        loop: false, // Disable infinite looping
        rewind: true, // Restart carousel when reaching the end
        margin: 10,
        nav: true,
        autoplay: true,
        items: 3,
        responsive: {
            0: { items: 1 }, // 1 item for screens up to 600px
            600: { items: 2 }, // 2 items for screens between 600px and 1000px
            1000: { items: 3 }, // 3 items for screens above 1000px
          },
      };
  return (
    <>
      <Nav />

      <section className={styles.boxAbtOne}>
                <div className="row">
                        <div className="col-lg-12">
                        <h4 className="abtInfoTop wow fadeInUp"  data-wow-duration="1s"> Supply of 220Kva and 250Kva Solar light.</h4>
                            <p className="paragraph wow fadeInUp"  data-wow-duration="1.2s">The supply of 220KVA and 250KVA solar-powered lighting systems to Entero Homes, Exonel Integrated Service, Safe Haven, and the NDDC (Niger Delta Development Commission) involves the provision and installation of high-capacity solar lights designed to offer sustainable and efficient lighting solutions. These systems, equipped with powerful solar panels and high-capacity batteries, are intended to meet the lighting needs of each organization or site, ensuring reliable illumination while reducing dependency on the national grid.</p>
                        </div>
                  </div>

          </section>

          
        <section className={styles.boxAbtOne}>
            <div className="row mb-5">
              <div className="col-lg-12 mb-4">
                        <h2 className="keyStepText wow fadeInUp"  data-wow-duration="1.2s">Revaloneq Nig. Ltd considers these key Aspects of the Supply and Installation:</h2>
              </div>

              <div className="col-lg-6 mb-2">
                    <div>
                        <div className="circleDivCarrier wow fadeInUp"  data-wow-duration="1s">
                            <div className='drawCircle mt-2'></div>
                            <h2 className="mg-bottom-24px wow fadeInUp fontWeightText"  data-wow-duration="1s"> Design and Capacity Selection:</h2>
                        </div>
                            <p className="paragraph wow fadeInUp"  data-wow-duration="1.2s">The solar lights, with capacities of 220KVA and 250KVA, are tailored to meet the energy demands of each facility. These systems are ideal for powering large-scale lighting needs, including streetlights, security lights, and other essential outdoor illumination</p>
                    </div>
              </div>


              <div className="col-lg-6 mb-2">
                    <div>
                        <div className="circleDivCarrier wow fadeInUp"  data-wow-duration="1s">
                            <div className='drawCircle mt-2'></div>
                            <h2 className="mg-bottom-24px wow fadeInUp fontWeightText"  data-wow-duration="1s"> High-Efficiency Solar Panels:</h2>
                        </div>

                            <p className="paragraph wow fadeInUp"  data-wow-duration="1.2s">The solar panels harness sunlight during the day, storing energy in high-capacity batteries for night-time use. This ensures that each site enjoys uninterrupted lighting throughout the night, even in areas with irregular power supply</p>
                    </div>
                </div>
            </div>
            
            <div className="row mb-5">

              <div className="col-lg-6 mb-5">
                    <div>
                        <div className="circleDivCarrier wow fadeInUp"  data-wow-duration="1s">
                            <div className='drawCircle mt-2'></div>
                            <h2 className="mg-bottom-24px wow fadeInUp fontWeightText"  data-wow-duration="1s"> Installation Process:</h2>
                        </div>
                        
                        <div className='paraTextCarrier'>
                            <p className="paragraph wow fadeInUp"  data-wow-duration="1.2s"> Skilled technicians will install the solar systems, ensuring optimal placement of the solar panels for maximum sunlight exposure. The systems will be designed to be weather-resistant and require minimal maintenance. Wiring and electrical components will be carefully secured to ensure long-term reliability.</p>
                            </div>
                    </div>
              </div>


              <div className="col-lg-6 mb-5">
                    <div>
                        <div className="circleDivCarrier wow fadeInUp"  data-wow-duration="1s">
                            <div className='drawCircle2 mt-2'></div>
                            <h2 className="mg-bottom-24px wow fadeInUp fontWeightText"  data-wow-duration="1s"> Sustainability and Cost Efficiency:</h2>
                        </div>
                            <p className="paragraph wow fadeInUp"  data-wow-duration="1.2s"> By adopting solar energy, the sites will benefit from reduced electricity costs, lower carbon emissions, and enhanced energy independence. The 220KVA and 250KVA systems are particularly suited for large properties like Entero Homes, Exonel Integrated Service, Safe Haven, and the NDDC, providing long-term, sustainable solutions for their lighting needs.</p>
                             
                    </div>
                </div>
            </div>

            <OwlCarousel className="owl-theme" {...options}>
                <div className="item">
                    <div className='solarImg'>
                        <img src={Avatar1} s alt='img' />
                    </div>
                </div>
                <div className="item">
                    <div className='solarImg'>
                        <img src={Avatar2} s alt='img' />
                    </div>
                </div>
                <div className="item">
                    <div className='solarImg'>
                        <img src={Avatar3} s alt='img' />
                    </div>
                </div>
                <div className="item">
                    <div className='solarImg'>
                        <img src={Avatar4} s alt='img' />
                    </div>
                </div>
                <div className="item">
                    <div className='solarImg'>
                        <img src={Avatar5} s alt='img' />
                    </div>
                </div>
                
            </OwlCarousel>

            {/* <div className="row mb-5">
            <div className="col-lg-10">
                    <div className="ser">
                            <h2 className="mg-bottom wow fadeInUp keyStepText"  data-wow-duration="1s">Benefits for Idama Community:</h2>
                    </div>

                    <div className="circleDivCarrier wow fadeInUp"  data-wow-duration="1s">
                            <div className='drawCircle1 mt-1'></div>
                            <p className="paragraph mg-bottom-24px wow fadeInUp "  data-wow-duration="1s">Enhanced Safety: Improved lighting in public areas reduces accidents and enhances security.</p>
                    </div>

                    <div className="circleDivCarrier wow fadeInUp"  data-wow-duration="1s">
                            <div className='drawCircle1 mt-1'></div>
                            <p className="paragraph mg-bottom-24px wow fadeInUp "  data-wow-duration="1s">Economic Savings: Eliminates the recurring costs associated with fuel-powered lighting.</p>
                    </div>
                    
                    <div className="circleDivCarrier wow fadeInUp"  data-wow-duration="1s">
                            <div className='drawCircle1 drawCircle4 mt-1'></div>
                            <p className="paragraph mg-bottom-24px wow fadeInUp "  data-wow-duration="1s">Environmental Impact: Reduces carbon emissions, contributing to a cleaner and greener environment.</p>
                    </div>

                    
                    <div className="circleDivCarrier wow fadeInUp"  data-wow-duration="1s">
                            <div className='drawCircle1 drawCircle3 mt-1'></div>
                            <p className="paragraph mg-bottom-24px wow fadeInUp "  data-wow-duration="1s">Empowered Community: Access to lighting extends business hours, supports education, and fosters community activities.</p>
                    </div>

                </div>
            </div> */}

            
            {/* <div className="col-lg-10">
                <div className="mt-5 mb-5">
                    <p className="mg-bottom wow fadeInUp fontWeightTex"  data-wow-duration="1s">Installing solar lights in Idama Community of Rivers State is a step toward resilience and sustainable development, illuminating not just pathways but the potential for growth and progress.</p>
                </div>
            </div> */}

        </section>



      <Footer />
    </>
  )
}

export default SupplyDetailPage