import React, { useEffect } from 'react'
import { useLocation } from "react-router-dom";
import Footer from '../components/Footer'
import Nav from '../components/Nav'
import styles from  '../css/home.module.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Avatar1 from '../../Image/1/1.jpg'
import Avatar2 from '../../Image/1/2.jpg'
import Avatar3 from '../../Image/1/3.jpg'
import Avatar4 from  '../../Image/1/4.jpg'
import Avatar5 from '../../Image/1/5.jpg'
import Avatar6 from '../../Image/1/6.jpg'

const SolarDetailPage = () => {

    const { state } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [state]);

    const options = {
        loop: false, // Disable infinite looping
        rewind: true, // Restart carousel when reaching the end
        margin: 10,
        nav: true,
        autoplay: true,
        items: 3,
        responsive: {
            0: { items: 1 }, // 1 item for screens up to 600px
            600: { items: 2 }, // 2 items for screens between 600px and 1000px
            1000: { items: 3 }, // 3 items for screens above 1000px
          },
      };
  return (
    <>
        <Nav />

        <section className={styles.boxAbtOne}>
            <div className="row">
                <div className="col-lg-10">
                    <h4 className="abtInfoTop wow fadeInUp"  data-wow-duration="1s">Installation of Solar Lights in Idama Rivers State.</h4>
                    <p className="paragraph wow fadeInUp"  data-wow-duration="1.2s">The installation of solar lights in Idama community of Rivers State is a transformative initiative aimed at enhancing sustainable development and improving the quality of life for the community. Solar lighting systems provide an environmentally friendly, cost-effective, and reliable energy solution, particularly for areas with limited access to grid electricity.</p>
                </div>
            </div>
        </section>

        <section className={styles.boxAbtOne}>
            <div className="row mb-5">
              <div className="col-lg-12 mb-4">
                        <h2 className="keyStepText wow fadeInUp"  data-wow-duration="1.2s">Key Steps REVALONEQ NIG. LTD carried in the Installation Process:</h2>
              </div>

              <div className="col-lg-6 mb-2">
                    <div>
                        <div className="circleDivCarrier wow fadeInUp"  data-wow-duration="1s">
                            <div className='drawCircle mt-2'></div>
                            <h2 className="mg-bottom-24px wow fadeInUp fontWeightText"  data-wow-duration="1s">Site Assessment</h2>
                        </div>
                            <p className="paragraph wow fadeInUp"  data-wow-duration="1.2s">Revaloneq Nig. Ltd carried out a thorough evaluation in Idama community to determine the most suitable locations for solar lights. Key considerations include areas with high foot traffic, public spaces, roads, and locations requiring enhanced security.</p>
                    </div>
              </div>


              <div className="col-lg-6 mb-2">
                    <div>
                        <div className="circleDivCarrier wow fadeInUp"  data-wow-duration="1s">
                            <div className='drawCircle mt-2'></div>
                            <h2 className="mg-bottom-24px wow fadeInUp fontWeightText"  data-wow-duration="1s">Design and Planning</h2>
                        </div>

                            <p className="paragraph wow fadeInUp"  data-wow-duration="1.2s">The design process involves choosing appropriate solar light models, considering factors like brightness, energy storage capacity, and weather resistance. The layout ensures optimal coverage and efficient energy use.</p>
                    </div>
                </div>
            </div>
            
            <div className="row mb-5">

              <div className="col-lg-6 mb-5">
                    <div>
                        <div className="circleDivCarrier wow fadeInUp"  data-wow-duration="1s">
                            <div className='drawCircle mt-2'></div>
                            <h2 className="mg-bottom-24px wow fadeInUp fontWeightText"  data-wow-duration="1s">Procurement of Materials</h2>
                        </div>
                        
                        <div className='paraTextCarrier'>
                            <p className="paragraph wow fadeInUp"  data-wow-duration="1.2s">High-quality solar panels, LED lights, poles, and battery systems are sourced. Components are selected based on durability and performance in the local climate.</p>
                            </div>
                    </div>
              </div>


              <div className="col-lg-6 mb-5">
                    <div>
                        <div className="circleDivCarrier wow fadeInUp"  data-wow-duration="1s">
                            <div className='drawCircle2 mt-2'></div>
                            <h2 className="mg-bottom-24px wow fadeInUp fontWeightText"  data-wow-duration="1s">Installation of Solar Panels and Lights</h2>
                        </div>
                            <p className="paragraph wow fadeInUp"  data-wow-duration="1.2s">Solar panels are mounted at angles that maximize sunlight absorption. Lights are installed on sturdy poles, anchored securely to withstand environmental factors like wind and rain.</p>
                    </div>
                </div>
            </div>


            <OwlCarousel className="owl-theme" {...options}>
                <div className="item">
                    <div className='solarImg'>
                        <img src={Avatar1} s alt='img' />
                    </div>
                </div>
                <div className="item">
                    <div className='solarImg'>
                        <img src={Avatar2} s alt='img' />
                    </div>
                </div>
                <div className="item">
                    <div className='solarImg'>
                        <img src={Avatar3} s alt='img' />
                    </div>
                </div>
                <div className="item">
                    <div className='solarImg'>
                        <img src={Avatar4} s alt='img' />
                    </div>
                </div>
                <div className="item">
                    <div className='solarImg'>
                        <img src={Avatar5} s alt='img' />
                    </div>
                </div>
                
                <div className="item">
                    <div className='solarImg'>
                        <img src={Avatar6} s alt='img' />
                    </div>
                </div>
            </OwlCarousel>

            <div className="row mb-5">
            <div className="col-lg-10">
                    <div className="ser">
                            <h2 className="mg-bottom wow fadeInUp keyStepText"  data-wow-duration="1s">Benefits for Idama Community:</h2>
                    </div>

                    <div className="circleDivCarrier wow fadeInUp"  data-wow-duration="1s">
                            <div className='drawCircle1 mt-1'></div>
                            <p className="paragraph mg-bottom-24px wow fadeInUp "  data-wow-duration="1s">Enhanced Safety: Improved lighting in public areas reduces accidents and enhances security.</p>
                    </div>

                    <div className="circleDivCarrier wow fadeInUp"  data-wow-duration="1s">
                            <div className='drawCircle1 mt-1'></div>
                            <p className="paragraph mg-bottom-24px wow fadeInUp "  data-wow-duration="1s">Economic Savings: Eliminates the recurring costs associated with fuel-powered lighting.</p>
                    </div>
                    
                    <div className="circleDivCarrier wow fadeInUp"  data-wow-duration="1s">
                            <div className='drawCircle1 drawCircle4 mt-1'></div>
                            <p className="paragraph mg-bottom-24px wow fadeInUp "  data-wow-duration="1s">Environmental Impact: Reduces carbon emissions, contributing to a cleaner and greener environment.</p>
                    </div>

                    
                    <div className="circleDivCarrier wow fadeInUp"  data-wow-duration="1s">
                            <div className='drawCircle1 drawCircle3 mt-1'></div>
                            <p className="paragraph mg-bottom-24px wow fadeInUp "  data-wow-duration="1s">Empowered Community: Access to lighting extends business hours, supports education, and fosters community activities.</p>
                    </div>

                </div>
            </div>

            
            <div className="col-lg-10">
                <div className="mt-5 mb-5">
                    <p className="mg-bottom wow fadeInUp fontWeightTex"  data-wow-duration="1s">Installing solar lights in Idama Community of Rivers State is a step toward resilience and sustainable development, illuminating not just pathways but the potential for growth and progress.</p>
                </div>
            </div>

        </section>

        <Footer />
    </>
  )
}

export default SolarDetailPage