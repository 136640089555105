import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route
} from "react-router-dom";
// Bootstrap CSS
import "bootstrap/dist/css/bootstrap.min.css";
// Bootstrap Bundle JS
import "bootstrap/dist/js/bootstrap.bundle.min";

import Home from './Pages/Home';
import Aboutus from './Pages/Aboutus';
import Services from './Pages/Services';
import Contact from './Pages/Contactus';
import Project from './Pages/Project';
import SolarDetailPage from './Pages/Projects/SolarDetailPage';
import Renovation from './Pages/Projects/RenovationOfClass';
import './App.css';
import { useEffect } from 'react';
import SolarLightPage from './Pages/Projects/SolarLightPage';
import FourClassroomsPage from './Pages/Projects/FourClassroomsPage';
import SupplyDetailPage from './Pages/Projects/SupplyDetailPage';

function App() {

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (

    <BrowserRouter>
       <Routes>
          <Route path="/" element={<Home />} exact/>
          <Route path="/About-us" element={<Aboutus />} exact/>
          <Route path="/Our-Services" element={<Services />} exact/>
          <Route path="/Contact-us" element={<Contact />} exact/>
          <Route path="/Projects" element={<Project />} exact/>
          <Route path="/Renovation_Of_Classroom_Detail" element={<Renovation />} exact/>
          <Route path="/Solar_installation_Page" element={<SolarDetailPage />} exact/>
          <Route path="/Solar_light_detail" element={<SolarLightPage />} exact/>
          <Route path="/Four_Classroom_Detail" element={<FourClassroomsPage />} exact/>
          <Route path="/Supply_Detail_Page" element={<SupplyDetailPage />} exact/>
        </Routes>
    </BrowserRouter>
    
  );
}

export default App;
