import React, { useEffect } from 'react'
import { useLocation } from "react-router-dom";
import Nav from '../components/Nav'
import Footer from '../components/Footer'
import styles from  '../css/home.module.css';
import OwlCarousel from 'react-owl-carousel';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import Avatar1 from '../../Image/3/1.jpg'
import Avatar2 from '../../Image/3/2.jpg'
import Avatar3 from '../../Image/3/3.jpg'
import Avatar4 from  '../../Image/3/4.jpg'
import Avatar5 from '../../Image/3/5.jpg'
import Avatar6 from '../../Image/3/6.jpg'
import Avatar7 from '../../Image/3/7.jpg'
import Avatar8 from '../../Image/3/8.jpg'
import Avatar9 from '../../Image/3/9.jpg'

const SolarLightPage = () => {
    const { state } = useLocation();

    useEffect(() => {
        window.scrollTo(0, 0);
    }, [state]);

    const options = {
        loop: false, // Disable infinite looping
        rewind: true, // Restart carousel when reaching the end
        margin: 10,
        nav: true,
        autoplay: true,
        items: 3,
        responsive: {
            0: { items: 1 }, // 1 item for screens up to 600px
            600: { items: 2 }, // 2 items for screens between 600px and 1000px
            1000: { items: 3 }, // 3 items for screens above 1000px
          },
      };
  return (
    <>
        <Nav />

        <section className={styles.boxAbtOne}>
            <div className="row">
                <div className="col-lg-12">
                    <h4 className="abtInfoTop wow fadeInUp"  data-wow-duration="1s"> Installation of solar light, GRA phase 1 Port harcourt, Rivers state</h4>
                    <p className="paragraph wow fadeInUp"  data-wow-duration="1.2s">The installation of solar lights in GRA Phase 1, Port Harcourt Rivers State involves the strategic placement of solar-powered streetlights to enhance lighting and improve safety in the area. Revaloneq Nig. Ltd began the process with site assessments to determine optimal locations for the lights, ensuring adequate coverage and minimal disruption.Qualified technicians from the Revaloneq Nig. Ltd team installed the poles, solar panels, and LED lights, ensuring all components are securely fixed and wired correctly</p>
                </div>

                                
                <div className="col-lg-11 mt-3">
                    <p className="paragraph wow fadeInUp"  data-wow-duration="1.2s">The project prioritizes eco-friendly solutions by reducing reliance on the grid, cutting energy costs, and minimizing carbon emissions. Once complete, the solar lights provide reliable, costeffective illumination throughout the GRA Phase 1 area, improving both night time visibility and the overall safety of residents and visitors. The system is low-maintenance, offering long-term benefits with minimal environmental impact. </p>
                </div>

                                
                <div className="col-lg-11 mt-3">
                    <p className="paragraph wow fadeInUp"  data-wow-duration="1.2s">The solar lights are designed to harness sunlight during the day, storing energy to power the lights during the night. The installation aims to promote energy efficiency, reduce electricity costs, and provide sustainable lighting for the Salvation Ministry Headquarter G.R.A Birabi and it's environ of Port Harcourt, Rivers State. </p>
                </div>

            </div>

            <OwlCarousel className="owl-theme" {...options}>
                <div className="item">
                    <div className='solarImg'>
                        <img src={Avatar1} s alt='img' />
                    </div>
                </div>
                <div className="item">
                    <div className='solarImg'>
                        <img src={Avatar2} s alt='img' />
                    </div>
                </div>
                <div className="item">
                    <div className='solarImg'>
                        <img src={Avatar3} s alt='img' />
                    </div>
                </div>
                <div className="item">
                    <div className='solarImg'>
                        <img src={Avatar4} s alt='img' />
                    </div>
                </div>
                <div className="item">
                    <div className='solarImg'>
                        <img src={Avatar5} s alt='img' />
                    </div>
                </div>
                
                <div className="item">
                    <div className='solarImg'>
                        <img src={Avatar6} s alt='img' />
                    </div>
                </div>
                <div className="item">
                    <div className='solarImg'>
                        <img src={Avatar7} s alt='img' />
                    </div>
                </div>
                <div className="item">
                    <div className='solarImg'>
                        <img src={Avatar8} s alt='img' />
                    </div>
                </div>
                <div className="item">
                    <div className='solarImg'>
                        <img src={Avatar9} s alt='img' />
                    </div>
                </div>
            </OwlCarousel>
  
        </section>



        <Footer />
    </>
  )
}

export default SolarLightPage